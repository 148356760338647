// extracted by mini-css-extract-plugin
export var productBox = "product-page-module--productBox--3npjq";
export var container = "product-page-module--container--3gs2M";
export var header = "product-page-module--header--jJ5Qf";
export var productDescription = "product-page-module--productDescription--FIAYd";
export var productImageWrapper = "product-page-module--productImageWrapper--1UTeC";
export var productImageList = "product-page-module--productImageList--AH8hX";
export var productImageListItem = "product-page-module--productImageListItem--oqhKu";
export var scrollForMore = "product-page-module--scrollForMore--2kRfB";
export var noImagePreview = "product-page-module--noImagePreview--2S-AG";
export var priceValue = "product-page-module--priceValue--2wdLx";
export var visuallyHidden = "product-page-module--visuallyHidden--26kws";
export var optionsWrapper = "product-page-module--optionsWrapper--1rsnt";
export var addToCartStyle = "product-page-module--addToCartStyle--3Cjua";
export var selectVariant = "product-page-module--selectVariant--u9G-9";
export var labelFont = "product-page-module--labelFont--20GH2";
export var tagList = "product-page-module--tagList--2xiR9";
export var breadcrumb = "product-page-module--breadcrumb--1Aniy";
export var productContainer = "product-page-module--productContainer--7oYvB";
export var metaSection = "product-page-module--metaSection--JtADA";